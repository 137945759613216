@import '../../../index.scss';

.Home {
    display: flex;
    background: #F6F6F6;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.StatusContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 20px;
    @media (min-width: 768px) {
        width: auto;
    }
}

.Filters {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.StatusHeader {
    border-bottom: 1px solid black;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: 768px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    gap: 10px;
}

.StatusData {
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    justify-content: center;
    color: #2DAB32;
}

.ListData{
    margin: 1rem;
    @media (min-width: 768px) {
        margin: 2.5rem;
    }
    margin-top: 0;
    background-color: white;
    border-radius: 20px;
    padding: 1rem;
    gap: 2rem;
}

.FiltersContainer {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    gap: 1rem;
}

.FilterInput {
    border:none;
    border-bottom: 1px solid black;
    background-color: transparent;
    &::placeholder{
        color: #888;
    }
}